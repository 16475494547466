export default {
  isOn: false,
  scrollbarSize: undefined,
  scrollTop: 0,

  getScrollbarSize() {
    if (typeof this.scrollbarSize !== 'undefined') return this.scrollbarSize;

    var doc = document.documentElement;
    var dummyScroller = document.createElement('div');
    dummyScroller.setAttribute('style', 'width:99px;height:99px;' + 'position:absolute;top:-9999px;overflow:scroll;');
    doc.appendChild(dummyScroller);
    this.scrollbarSize = dummyScroller.offsetWidth - dummyScroller.clientWidth;
    doc.removeChild(dummyScroller);
    return this.scrollbarSize;
  },

  hasScrollbar() {
    return document.documentElement.scrollHeight > window.innerHeight;
  },

  on() {
    if (typeof document === 'undefined' || this.isOn) return;

    var doc = document.documentElement;
    this.scrollTop = window.pageYOffset;

    if (this.hasScrollbar()) {
      doc.style.width = 'calc(100% - '+ this.getScrollbarSize() +'px)';
    } else {
      doc.style.width = '100%';
    }

    doc.style.position = 'fixed';
    doc.style.top = -this.scrollTop + 'px';
    doc.style.overflow = 'hidden';

    this.isOn = true;
  },

  off() {
    if (typeof document === 'undefined' || !this.isOn) return;
    var doc = document.documentElement;
    doc.style.width = '';
    doc.style.position = '';
    doc.style.top = '';
    doc.style.overflow = '';
    window.scroll(0, this.scrollTop);
    this.isOn = false;
    this.scrollTop = 0;
  },

  toggle() {
    if (this.isOn) {
      this.off();
      return;
    }

    this.on();
  }
}
