import VideoPlayer from '@vimeo/player';

export default class LazyVimeo {
  constructor(root, url, options = {}) {
    this.root_ = root;
    this.url_ = url;
    this.options_ = {};
    this.vimeoObject_ = null;

    // Get fields
    Object.assign(this.options_, options);
  }

  async getPlayer_() {
    if (this.vimeoObject_) {
      return this.vimeoObject_;
    }

    // Load src
    this.root_.src = this.url_;

    // Init vimeo player
    this.vimeoObject_ = new VideoPlayer(this.root_);

    return this.vimeoObject_;
  }

  async play() {
    const player = await this.getPlayer_();
    return player.play();
  }

  async setVolume(value) {
    const player = await this.getPlayer_();
    return player.setVolume(value);
  }

  async pause() {
    const player = await this.getPlayer_();
    return player.pause();
  }

  async stop() {
    const player = await this.getPlayer_();

    await player.pause();

    return player.setCurrentTime(0);
  }
}
