// All files you want to be handled by webpack you import here.
// This is why webpack handles main.js as a entry point and transpile
// SASS, images, fonts, and other media included in the entry point.

// Import favicons
import cursor from './img/pointer.svg';
import pointer from './img/cursor.svg';

// Import main sass entry points. if you import more sass files here,
// they would be merged together into one. See how this is handeled
// on line 218 in webpack.prod.js
// Import styles
import './main.scss';

// Import fonts used. They go through a file loader that
// put them into dist/fonts when compiled. See how this is
// handeled in line 62 in webpack.common.js
// Import fonts
import '@/fonts/NeueMontreal-Medium.woff';
import '@/fonts/NeueMontreal-Medium.woff2';
import '@/fonts/NeueMontreal-MediumItalic.woff';
import '@/fonts/NeueMontreal-MediumItalic.woff2';

// Importing vimeo player js library installed with npm.
// NPM modules are resolved automatically that is why you dont
// need ./ infront when importing.
// Import dependencies
import VideoPlayer from '@vimeo/player';
import scrollTo from 'animated-scroll-to';

// Local dependencies
import { delay } from './util';
import noScroll from './util/noScroll';
import LazyVimeo from './components/LazyVimeo';

// Make a "singleton-like" object for the project. Using
// const we make sure there cannot be another ProjectName in
// the global scope. Before looking into the object scroll
// to the bottom.
//
// Application
//
const ProjectName = {
  // Options / globals
  //
  // This is triggered when DOM is ready.
  // In this function you can trigger other functions
  // and be sure that the DOM element you are using
  // has loaded.
  init() {
    // this.cursorEl = document.querySelector('#TheCursor');
    [...document.querySelectorAll('.VideoThumbnail')].forEach( parent => {
      const hoverEl = parent.querySelector('.VideoThumbnail__hover iframe');
      const videoEl = parent.querySelector('.VideoThumbnail__video iframe');
      let isPlaying = false;
      let hoverPlayer = null;
      let videoPlayer = null;

      if(hoverEl && hoverEl.dataset.src && !('ontouchstart' in document.documentElement)) {
        hoverPlayer = new LazyVimeo(hoverEl, hoverEl.dataset.src);

        parent.addEventListener('mouseenter', async () => {
          console.log('mouseneter')
          if (isPlaying) {
            return;
          }
          await hoverPlayer.play();
          // Safari shiity blink bug
          await delay(66);
          parent.classList.add('is-hovering');
        }, false);

        parent.addEventListener('mouseleave', async () => {
          console.log('mouseleave')
          if (isPlaying) {
            return;
          }

          parent.classList.remove('is-hovering');
          await hoverPlayer.stop();
        }, false);
      }

      if(videoEl && videoEl.dataset.src) {
        videoPlayer = new LazyVimeo(videoEl, videoEl.dataset.src);

        const playVideoHandler = async () => {
          console.log('play')
          isPlaying = true;
          parent.removeEventListener('click', playVideoHandler);
          await videoPlayer.play().catch(error => console.log(error));
          await delay(66);
          parent.classList.add('is-playing');

          if (hoverPlayer) {
            parent.classList.remove('is-hovering');
            hoverPlayer.stop();
          }
        }

        parent.addEventListener('click', playVideoHandler, false);
      }
    });

    this.setupToggles();
    this.setupScrollTos();
    this.setupAnimations();
    this.setupIsInView();
    this.initHoverEmbeds();

    // On browser resize
    window.addEventListener('resize', () => this.onResize());
    window.addEventListener('scroll', () => this.onScroll());
  },

  onScroll() {
    if (window.pageYOffset > window.innerHeight) {
      document.body.classList.add('is-past-initial-viewport');
    } else {
      document.body.classList.remove('is-past-initial-viewport');
    }
  },

  setupScrollTos() {
    [...document.querySelectorAll('[data-scroll-to]')].forEach((el, i) => {
      if(!el.dataset.scrollTo) {
        return;
      }

      const target = document.querySelector(el.dataset.scrollTo);

      if (!target) {
        return;
      }

      el.addEventListener('click', (evt) => {
        evt.preventDefault();

        scrollTo(target);
      });
    });
  },

  initHoverEmbeds() {
    [...document.querySelectorAll('.ListWithVideo__item')].forEach((parent, i) => {
      const embed = parent.querySelector('iframe');
      const link = parent.querySelector('a');

      if(!embed || !embed.dataset.src || !link || ('ontouchstart' in document.documentElement)) {
        return;
      }

      const player = new LazyVimeo(embed, embed.dataset.src);

      link.addEventListener('mouseenter', async () => {
        await player.play();
        // Safari shiity blink bug
        await delay(66);
        parent.classList.add('is-hovering');
      }, false);

      link.addEventListener('mouseleave', () => {
        parent.classList.remove('is-hovering');
        player.stop();
      }, false);
    });

  },

  setupToggles() {
    const toggleGroups = {};

    [...document.querySelectorAll('[data-toggle]')].forEach((el, i) => {
      if(!el.dataset.toggle) {
        return;
      }

      const target = document.querySelector(el.dataset.toggle);
      const toggleGroupId = el.dataset.toggleGroup;

      if (!target) {
        return;
      }

      el.addEventListener('click', (evt) => {
        evt.preventDefault();
        if(el.classList.contains('is-active')) {
          noScroll.off();
          el.classList.remove('is-active');
          target.classList.remove('is-active');
          document.body.classList.remove(`toggle-${el.dataset.toggle.replace('#', '')}-is-active`);
          if (toggleGroupId) {
            document.body.classList.remove(`toggle-group-${toggleGroupId}-is-active`);
            toggleGroups[toggleGroupId] = null;
          }
        } else {
          el.classList.add('is-active');
          target.classList.add('is-active');
          document.body.classList.add(`toggle-${el.dataset.toggle.replace('#', '')}-is-active`);

          if(el.hasAttribute('data-toogle-lock')) {
            noScroll.on();
          }

          if (toggleGroupId) {
            if(toggleGroups[toggleGroupId]) {
              toggleGroups[toggleGroupId].el.classList.remove('is-active');
              toggleGroups[toggleGroupId].target.classList.remove('is-active');
              document.body.classList.remove(`toggle-${toggleGroups[toggleGroupId].el.dataset.toggle.replace('#', '')}-is-active`);
            }
            document.body.classList.add(`toggle-group-${toggleGroupId}-is-active`);

            // [...document.querySelectorAll(`[data-toggle-group=${toggleGroupId}])`].forEach(el => el.classList.add(`toggle-group-sibling-${toggleGroupId}-is-active`));

            toggleGroups[toggleGroupId] = {
              el,
              target
            };
          }
        }
      }, false);
    });

  },

  setupAnimations() {
    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('is-animated');
        } else {
          entry.target.classList.remove('is-animated');
        }
      });
    }

    let observer = new IntersectionObserver(handleIntersection, {
      // rootMargin: '0px',
      threshold: 1
    });

    [...document.querySelectorAll('.should-animate')].forEach(el => observer.observe(el));
  },

  setupIsInView() {
    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          document.body.classList.add(`is-in-view-${entry.target.dataset.inView}`);
        } else {
          document.body.classList.remove(`is-in-view-${entry.target.dataset.inView}`);
        }
      });
    }

    let observer = new IntersectionObserver(handleIntersection, {
      // rootMargin: '0px',
      threshold: 0
    });

    [...document.querySelectorAll(['[data-in-view]'])].forEach(el => observer.observe(el));
  },

  initOnLoad() {
    console.log('Made with ❤️❤️❤️ by ANTI');
    console.log('Developed by Lasse Mejlvang Tvedt and Stig Melón-Bratvold 😏');
    console.log('https://anti.as');
  },

  onResize() {
    console.log('on resize');
  }
};

// Only run project initalization when DOM is ready. This make sure all
// elements in the document is loaded before be try getting them with
// javascript. Note how ProjectName is the global object from above.
document.addEventListener('DOMContentLoaded', () => ProjectName.init());

// Trigger an event when the website is loaded. This is only triggered when everything
// (including images and other "external" assets) is loaded.
window.addEventListener('load', () => ProjectName.initOnLoad());

// This is used for automatically updating the page when making changes to the code.
// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}


// Accordion to open education/teaching on people page
// const accordions = document.querySelectorAll(".TheHeader");
//
// accordions.forEach(accordion => {
// 	const title = accordion.querySelector('.directors-title');
//
// 	title.addEventListener('click', e => {
// 		accordion.classList.toggle('is-active');
// 	})
// })
//
//
// // Open main navigation
// $(document).ready(function(){
//   $('.regissor-open').click(function(){
// 	$('body').toggleClass('nav-open');
//   });
// });
//
//
//
// const fadeup = document.querySelectorAll(".fadeUp")
//
// const observer = new IntersectionObserver(entries => {
//   entries.forEach(entry => {
//     if (entry.intersectionRatio >= 0.1) {
//       entry.target.classList.add("in-view")
//     }
//   })
// }, {
//   threshold: [0, 0.1, 1]
// });
//
// fadeup.forEach(holder => {
//   observer.observe(holder)
// })
